<template>
  <div :style="listStyle" class="my-4 mx-5 flex-grow-1">
    <Info
      v-for="(config, index) in configs"
      :key="index"
      :index="index"
      v-bind="config"
      :active="true"
    />
  </div>
</template>

<script>
export default {
  components: {
    Info: () => import("./info.vue"),
  },
  data: () => ({
    activeIndex: 0,
    configs: [
      { title: "Step 01",subtitle:"簽約前作業", content: "初步需求訪談<br>需求確認<br>評估與報價<br>簽約" },
      {
        title: "Step 02",subtitle:"啟動",
        content: `細節需求訪談<br>流程圖與框架圖設計與確認<br>產品需求規劃書PRD撰寫與確認<br>
        程式開發&自動化單元測試<br>整合測試`,
      },
      {
        title: "Step 03",subtitle:"驗收",
        content: `交付驗收<br>驗收修正與確認<br>教育訓練<br>弱點掃描(費用另計)<br>壓力測試(費用另計)`,
      },
      { title: "Step 04",subtitle:"結案", content: `交付結案報告與相關檔案<br>交付保固書` },
    ],
    moveY: 0,
  }),
  computed: {
    listStyle() {
      return {
        transition: "transform 0.2s",
        transform: `translateY(${this.moveY}px)`,
      };
    },
  },
  created() {
    // this.$root.$on("infoWheelEvent", this.handleWheelEvent);
  },
  beforeDestroy() {
    // this.$root.$off("infoWheelEvent", this.handleWheelEvent);
  },
  methods: {
    async handleWheelEvent(deltaY) {
      // wheel向下
      if (deltaY > 0) {
        if (this.activeIndex >= this.configs.length - 1) return;
        this.activeIndex += 1;
        await this.$helper.delay(0.8);
        // 測量前一個的高 + margin-top
        const $lastTarget = $(this.$children[this.activeIndex - 1].$el);
        this.moveY -= $lastTarget.outerHeight(true); // 這個會抓到height + 上下margin
        return;
      }

      // wheel向上
      if (this.activeIndex == 0) return;
      const $lastTarget = $(this.$children[this.activeIndex - 1].$el);
      const height = $lastTarget.outerHeight(true);
      this.activeIndex -= 1;
      await this.$helper.delay(0.8);
      this.moveY += height;
    },
  },
};
</script>